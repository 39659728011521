import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { hideLoading, showLoading } from '@app/data/constants/general.const';
import { AuthService } from '@app/services/user/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {

  formGroup: FormGroup;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private authService: AuthService) { 
    this.formGroup = this.fb.group({
      old_password: [''],
      new_password: ['']
    });   
  }

  ngOnInit(): void {
  }

  changePassword(): void{
    showLoading();
    this.authService.changePassword(this.formGroup.value).subscribe((res) => {
      if(!res.success){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.errors[0]
        });
      }else{
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: res.data
        }).then(()=>{
          this.bsModalRef.hide();
          hideLoading();
        })
      }
    });
  }

}
